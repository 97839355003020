import React from "react";
import style from "./EmptyStateNoMedications.module.css";
import emptyIcon from "../../../../assets/images/medication.svg";

const EmptyState = () => {
    return (
        <div className={style.emptyState}>
            <img className={style.element} alt="Element" src={emptyIcon} />
            <div className={style.text}>
                <div className={style.div}>
                    <div className={style.textWrapper}>No medications</div>
                    <p className={style.onceThePatient}>
                        Once the patient receives a prescription, it will appear here..
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmptyState;

