import styles from "./ConsultationCard.module.css";

const ConsultationCard = () => {
    return (
        <div className={styles.nnumbersParent}>
            <div className={styles.nnumbers}>
                <div className={styles.upcomingConsultation}>Upcoming consultation</div>
                <div className={styles.march2023Parent}>
                    <b className={styles.b}>24 March 2023</b>
                    <b className={styles.b}>15:30</b>
                </div>
            </div>
            <div className={styles.button}>
                <div className={styles.bookAnAppointment}>Start consultation</div>
            </div>
        </div>);
}

export default ConsultationCard;