import React, { useState, useEffect } from "react";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';


import srcicon from "../../assets/images/srcicon.svg";
import logo from "../../assets/images/Logo.svg";
import logicon from "../../assets/images/logicon.svg";
import navi1 from "../../assets/images/navi1.svg";
import navi3 from "../../assets/images/navi3.svg";
import myappointment from "../../assets/images/myappointments.svg";
import navi4 from "../../assets/images/navi4.svg";
import Sidebar from "../Sidebar/Sidebar";
import home from "../../assets/images/home.svg";
import patient from "../../assets/images/patient.svg";
import result from "../../assets/images/results.svg";
import documents from "../../assets/images/documents.svg";
import vaccine from "../../assets/images/vacinnations.svg";
import oppor from "../../assets/images/opportunities.svg";
import communication from "../../assets/images/communication.svg";
import reports from "../../assets/images/reports.svg";
import search from "../../assets/images/search.svg";
import settings from "../../assets/images/settings.svg";
import home2 from "../../assets/images/home2.svg";
import patient2 from "../../assets/images/patient2.svg";
import result2 from "../../assets/images/results2.svg";
import documents2 from "../../assets/images/documents2.svg";
import vaccine2 from "../../assets/images/vacinnations2.svg";
import oppor2 from "../../assets/images/opportunities2.svg";
import arrowLeft from "../../assets/images/arrow-left.svg";
import communication2 from "../../assets/images/communication2.svg";
import reports2 from "../../assets/images/reports2.svg";
import search2 from "../../assets/images/search2.svg";
import settings2 from "../../assets/images/settings2.svg";
import dropi1 from "../../assets/images/dropi1.svg";
import dropi2 from "../../assets/images/dropi2.svg";
import dropi3 from "../../assets/images/dropi3.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { createAxiosInstance } from "../../api/axiosConfig";
import {
  WHO_AM_I,
  PATIENT_SEARCH_AUTOCOMPLETE_URL,
  TASK_CONTROLER_CLOSE,
  TASK_CONTROLER_URL,
  DELETE_TASK, TASK_CONTROLER_URL_OPEN_USER
} from "../../api/Service";
import { useDispatch, useSelector } from "react-redux";
import style from "../../components/Header/Header.css";
import { removeuser } from "../../redux/user/userSlice.js";
import { logoutFromHelper } from "../../api/Helper.js";
import { logout } from "../../redux/auth/AuthSlice.js";
import moment from "moment";
import LogOutModal from "../Modal/LogOutModal";
import toast, { Toaster } from "react-hot-toast";
import { Image } from "react-bootstrap";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  addPatientFullName,
  addPatientId,
  clearPatient,
  clearPatientFullName,
  clearPatientId,
} from "../../../src/redux/patient/PatientSlice";
import s from "../helpers/Modal/Modal.module.css";
import Close from "../../assets/images/close-black.svg";
import information from "../../assets/images/information.svg";
import tick from "../../assets/images/tick.svg";
import Delete from "../../assets/images/delete.svg";
import Pagination from "../Pagination";

function Header() {
  const [isDesktop, setIsDesktop] = useState(true);
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const [selectedUser, setSelectedUser] = useState({});
  const [fullName, setFullName] = useState("");
  const [options, setOptions] = useState([]);
  const [patientId, setPatientId] = useState(null);
  // For navbar toogle in responsive view//
  const [selectState, setSelectState] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [title, setTitle] = useState("");

  let tenantVal = useSelector((state) => state.auth.user?.tenant);
  if (!tenantVal) {
    tenantVal = localStorage.getItem("kinesin-tenant");
  }
  console.log(options);
  let nameState = useSelector((state) => state.auth.user?.name);
  if (!nameState) {
    nameState = localStorage.getItem("kinesin-name");
  }
  const splittedNameState = nameState ? nameState.split(" ") : "";
  const handleMenuClick = (itemName) => {
    setActiveItem(itemName); // Update the active state when a menu item is clicked
  };

  // For navbar toogle in responsive view//

  const userState = useSelector((state) => state.user);
  const [globalSearchText, setGlobalSearchText] = useState("");
  const navToggle = () => {
    const body = document.querySelector("html");
    setSelectState(!selectState);
    body.classList.toggle("show-menu");
  };

  const mytasksToggle = () => {
    console.log(open);
    let toggle = !open;
    setOpen(toggle)
  };

  const getData = async (searchTerm) => {
    try {
      if (!searchTerm) setOptions([]);

      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_SEARCH_AUTOCOMPLETE_URL}/${searchTerm}`
      );

      if (response && response.data && response.data.length > 0) {
        const updatedOptions = response.data.map((p) => {
          return {
            id: p.id,
            title:
              p.firstName +
              (p.surname ? " " + p.surname : "") +
              (p.dateOfBirth
                ? " (" + moment(p.dateOfBirth).format("DD-MM-YYYY") + ")"
                : ""),
          };
        });
        setOptions(updatedOptions);
      } else setOptions([]);
    } catch (error) {
      console.log(error);
    }
  };
  const onInputChange = (event, value, reason) => {
    if (value && value.length > 2) {
      getData(value);
    } else {
      setTimeout(() => {
        setOptions([]);
      }, 0);
    }
  };
  const goToPatientDetails = async (id, patientFullName) => {

    if (!id) return;

    dispatch(addPatientId(id));
    localStorage.setItem("kinesin-patientId", id);
    dispatch(addPatientFullName(patientFullName));
    const isPatientDetailPage = window.location.pathname === "/patient-detail";
    console.log(id);
    console.log(" same page : ", isPatientDetailPage);

    if (!isPatientDetailPage) {
      navigate("/patient-detail", { state: { samePage: false } });
      setTimeout(() => {
        setFullName("");
      }, 1200);
    } else {
      navigate("/patient-detail", { state: { samePage: true } });
      setTimeout(() => {
        setFullName("");
      }, 500);
    }
  };
  const fetchProfileInfo = async () => {
    try {
      const axiosInstance = createAxiosInstance(true);
      const response = await axiosInstance.get(`${WHO_AM_I}`);
      if (response && response.data) {
        if (response.data) {
          setSelectedUser(response.data.staffMember);
        }
      }
      setIsPageLoaded(true);
    } catch (error) {
      console.log(error);
      errorHandling(error);
    }
  };
  const location = useLocation();
  const isActive = (path) => location.pathname === path;

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  const enterKeyGlobalSearch = (e) => {
    if (e.key === "Enter") {
      var isInPatientTab = false;
      if (window.location.href) {
        var c = window.location.href.split("/");
        if (c) {
          isInPatientTab = c[c.length - 1] == "patient";
        }
      }

      console.log(
        "do validate : " +
          globalSearchText +
          " , " +
          c[c.length - 1] +
          " , " +
          (isInPatientTab ? "yes" : "no")
      );
      console.log(globalSearchText);
      if (!isInPatientTab) {
        navigate("/patient", { state: { searchQuery: globalSearchText } });
      } else {
        navigate("/patient", { state: { searchQuery: globalSearchText } });
      }
      setGlobalSearchText("");
    }
  };

  // dynamic change title based on url logic
  useEffect(() => {
    switch (location.pathname) {
      case "/allied_home":
        setTitle("Kinesin Health");
        break;
      case "/patient":
        setTitle("All patients");
        break;
      case "/patient-detail":
        setTitle("Patient detail");
        break;
      case "/patient-detail-gp":
        setTitle("Patient detail");
        break;
      case "/patient-documents-gp":
        setTitle("Patient documents");
        break;
      case "/patient-communication":
        setTitle("Patient communications");
        break;
      case "/patient-payments":
        setTitle("Patient payments");
        break;
      case "/patient-medication":
        setTitle("Patient medications");
        break;
      case "/patient-consult":
        setTitle("Patient consultation");
        break;
      case "/patient-family":
        setTitle("Patient family");
        break;
      case "/allied_sms":
        setTitle("Communications");
        break;
      case "/patient-protocols":
        setTitle("Patient protocols");
        break;
      case "/patient-results":
        setTitle("Patient results");
        break;
      case "/patient-immunizations":
        setTitle("Patient immunizations");
        break;
      case "/appointments":
        setTitle("Appointments");
        break;
      case "/myappointments":
        setTitle("My Appointments");
        break;
      case "/mypatients":
        setTitle("My Patients");
        break;
      case "/mytasks":
        setTitle("My Tasks");
        break;
      case "/allied_documents":
        setTitle("Documents");
        break;
      case "/practice_details":
        setTitle("Settings - Practice details");
        break;
      case "/user_details":
        setTitle("Settings - User details");
        break;
      case "/service_details":
        setTitle("Settings - Service details");
        break;
      case "/practice_hours_details":
        setTitle("Settings - Practice opening hours");
        break;
      case "/sms_settings_details":
        setTitle("Settings - Sms Templates");
        break;
      case "/notifications_details":
        setTitle("Settings - Notifications");
        break;
      case "/payments_details":
        setTitle("Settings - Payments");
        break;

      default:
        setTitle("Kinesin Health");
    }
  }, [location.pathname]);
  const handleBackClick = () => {
    navigate(-1);
  };


  //TASKS
  const [currentPage, setCurrentPage] = useState(1); // For pagination state//
  const [selectedTaskId, setSelectedTaskId] = useState(null); //State for selected task id
  const [selectedTask, setSelectedTask] = useState({});
  const [modalHeaderTitle, setModalHeaderTitle] = useState(""); // State for modal header title
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [task, setTask] = useState([]); //State for taking static data
  const [dataFetching, setDataFetching] = useState(true);
  const [staffMemberId, setStaffMemberId] = useState();
  const [dynamicDataSource, setDynamicDataSource] = useState([]);
  const itemsPerPage = 25; // Number of items to display per page
  const totalPages = Math.ceil(task.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = task.slice(indexOfFirstItem, indexOfLastItem);

  const handleAddTask = () => {
    setSelectedTask(null);
    setSelectedTaskId(null);
    setModalHeaderTitle("Add Task");
    setShowModal(true);
  };

  const handleSaveTask = (newTask) => {
    let updatedStatus = newTask.status; // Declare a variable to hold the updated status

    if (selectedTaskId) {
      // Edit existing task
      const updatedTasks = task.map((item) =>
          item.id === selectedTaskId
              ? { ...newTask, status: updatedStatus }
              : item
      );
      setTask(updatedTasks);
    } else {
      // Add new task
      const updatedTasks = [...task, newTask];
      setTask(updatedTasks);
    }
    setShowModal(false);
  };
  const handleCloseTask = async (id) => {
    try {
      if (!id) return;
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
          `${tenantVal}${TASK_CONTROLER_CLOSE}`.replace("{taskId}", id)
      );
      if (response.status == 200) {
        setStaffMemberId("");
        setShowModal(false);
        toast.success("Task completed successfully.");
      }
      fetchTaskNew();
    } catch (error) {
      errorHandling(error)
    }
  };
  const createNewTask = async (userData) => {
    try {
      const axiosInstance = createAxiosInstance();
      let response;
      if (selectedTaskId) {
        // If selectedTaskId is not null, it means we are editing an existing task
        const editedData = { ...userData, id: selectedTaskId };
        response = await axiosInstance.post(
            `${tenantVal}${TASK_CONTROLER_URL}`,
            editedData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        );
        toast.success("Task updated successfully");
      } else {
        response = await axiosInstance.post(
            `${tenantVal}${TASK_CONTROLER_URL}`,
            userData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        );
        toast.success("New Task created successfully");
      }
      fetchTaskNew();
    } catch (error) {
      errorHandling(error);
    }
  };


  const fetchTaskNew = async () => {
    try {
      let ar = [];
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
          `${tenantVal}${TASK_CONTROLER_URL_OPEN_USER}`
      );
      if (response && response.data) {
        setDataFetching(false);

        var i = 0;
        response.data.forEach((element) => {
          i++;
          ar.push({
            id: element.id,
            key: element.id,
            name: element.name,
            no: (
                <li
                    key={i}
                    data-bs-toggle="modal"
                    data-bs-target="#displayTask_modal"
                    onClick={() => handleDisplayTask(element.id)}
                >
                  <p>
                    <span className="number-wrap hello">{i}</span>{" "}
                    <span className="text-ellipsis"> {element.title}</span>
                  </p>
                  <div className="delbtnlist">
                    <button
                        className={element.status == "Complete" ? "green-tick" : ""}
                    >
                      <img src={tick} alt="" />
                    </button>
                    <button
                        data-bs-toggle="modal"
                        data-bs-target="#myModal"
                        onClick={() => handleDeleteTask(element.id)}
                    >
                      <img src={Delete} alt="" />
                    </button>
                  </div>
                </li>
            ),
          });
        });
        setDynamicDataSource(ar);
        setIsPageLoaded(true);
      }
      const data = response.data.sort((a, b) => a.id - b.id);
      setTask(data);
    } catch (error) {
      errorHandling(error);
    }
  };

  const getPatientName = (patient) => {
    if(patient && patient !== undefined) {
      return patient.firstName + ' ' + patient.surname;
    }
    return '';
  }

  const fetchTask = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
          `${tenantVal}${TASK_CONTROLER_URL}`
      );
      const data = response.data.sort((a, b) => a.id - b.id);
      setTask(data);
    } catch (error) {
      errorHandling(error);
    }
  };

  useEffect(() => {
    fetchTaskNew();
  }, []);

  const handleDeleteTask = (id) => {
    setSelectedTaskId(id);
    setShowModal(false);
  };

  const handleDisplayTask = async (id) => {
    const selectedTask = task.find((item) => item.id === id);
    if (selectedTask) {
      setSelectedTask(selectedTask);
    }
  };

  const deleteTask = async () => {
    if (selectedTaskId) {
      try {
        const axiosInstance = createAxiosInstance();
        const url = `${tenantVal}${DELETE_TASK}`;
        await axiosInstance.delete(url, {
          data: {
            id: selectedTaskId,
            tenant: tenantVal,
          },
        });
        // Filter the task array to remove the selected task
        const updatedTasks = task.filter((item) => item.id !== selectedTaskId);
        setTask(updatedTasks);
        setShowModal(false);
        toast.success("Task deleted successfully.");
      } catch (error) {
        errorHandling(error);
      }
    }
  };

  const errorHandling = (error) => {
    if (error && error.response) {
      if (error.response.data && error.response.data.message)
        toast.error(error.response.data.message);

      if (error.response.status && error.response.status == 403) {
        logoutFromHelper();
        dispatch(logout());
        dispatch(removeuser());
        navigate("/logout");
      }
    }
  };

  //END TASKS

  return (
    <>
      {" "}
      {isDesktop ? (
        <header>
          <div className="d-flex align-items-center gap-4">
            <Link className="navBackBtn" onClick={handleBackClick}>
              <img src={arrowLeft} alt="" onClick={handleMenuClick} />
            </Link>
            <h2 className="header-title">{title}</h2>
          </div>

          <div className="header-links-wrap d-flex align-items-center gap-2">
            <div className="d-flex align-items-center gap-4">
              <div className="search_bar search-wrapper">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  value={fullName || null}
                  options={options}
                  getOptionLabel={(option) =>
                    option && option.title ? option.title : fullName
                  }
                  onInputChange={onInputChange}
                  onChange={(event, newValue) => {
                    setFullName(newValue ? newValue.title : "");
                    setPatientId(newValue ? newValue.id : "");
                    goToPatientDetails(newValue ? newValue.id : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      placeholder="Search here..."
                    />
                  )}
                  className="material-input"
                />
                <img src={srcicon} alt="search-icon" />
              </div>
              <Link
                  to="/appointments"
                  style={{ color: "#fff", borderRadius: '12px', padding: '10px' }}
                  className={`appointmentsLink ${
                      isActive("/appointments") ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("appointments")}
              >

                Book an appointment
              </Link>
              <Link
                  to="/myappointments"
                  className={`nav__link ${
                      isActive("/myappointments") ? "active" : ""
                  }`}
                  style={{minWidth: '120px'}}
                  onClick={() => navToggle("myappointments")}
              >
                    <span className="navicon sidebarImg">
                      <img src={myappointment} alt="" />
                    </span>
                My Appointments
              </Link>
                <Link
                    className="nav__link"
                    onClick={() => mytasksToggle("")}
                >
                    <span className="navicon sidebarImg">
                      <img src={navi1} alt="" />
                    </span>
                  My Tasks
                </Link>
              <Link
                  to="/mypatients"
                  className={`nav__link ${
                      isActive("/mypatients") ? "active" : ""
                  }`}
                  style={{minWidth: '120px'}}
                  onClick={() => navToggle("mypatients")}
              >
                    <span className="navicon sidebarImg">
                      <img src={myappointment} alt="" />
                    </span>
                My Patients
              </Link>
            </div>
            {/* <Link to="/allied_task">
              <span className="navicon">
                <img src={navi1} alt="" />
              </span>
              Tasks
            </Link>
            <Link to={(e) => e.preventDefault()}>
              <span className="navicon">
                <img src={navi4} alt="" />
              </span>
              Payments
            </Link> */}
            <div
              className="hedconttoggel"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {isPageLoaded && (
                <div className="logiconbox">
                  {selectedUser?.profilePic ? (
                    <img
                      src={selectedUser?.profilePic}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "100%",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <span
                      className="user-profile-image"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "100%",
                        cursor: "pointer",
                      }}
                    >
                      {splittedNameState &&
                      splittedNameState[0] &&
                      splittedNameState[0][0]
                        ? splittedNameState[0][0]
                        : ""}
                      {splittedNameState &&
                      splittedNameState[0] &&
                      splittedNameState[0][0]
                        ? splittedNameState[1][0]
                        : ""}
                    </span>
                  )}
                </div>
              )}

              <div className="dropdown">
                <button
                  className="dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                >
                  {nameState}
                </button>

                <ul
                  className="dropdown-menu profiledropdown"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="profilelink bdrbttom">
                    <div className="profilelinkdetails">
                      {userState && (
                        <>
                          <h6>{nameState}</h6>
                          <p>{userState.email}</p>
                        </>
                      )}
                    </div>
                  </li>
                  <li>
                    <Link
                      className="profilelink"
                      to="/profile"
                      onClick={() => navigate("/profile")}
                    >
                      <div className="dropicon">
                        <img src={dropi1} alt="" />
                      </div>
                      <div className="profilelinkdetails">
                        <h6>Profile</h6>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="profilelink"
                      to="/timeoff"
                      onClick={() => navigate("/timeoff")}
                    >
                      <div className="dropicon">
                        <img src={dropi2} alt="" />
                      </div>
                      <div className="profilelinkdetails">
                        <h6>Time off</h6>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="deletebtn profilelink"
                      data-bs-toggle="modal"
                      data-bs-target="#logoutModal"
                    >
                      <div className="dropicon">
                        <img src={dropi3} alt="" />
                      </div>
                      <div className="profilelinkdetails">
                        <h6>Logout</h6>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <header>
          <div className="d-flex align-items-center flex-shrink-0">
            <div onClick={navToggle} className={icon}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>

            <Link to="/" className="header-logo">
              <Image src={logo} alt="header-logo" />
            </Link>
          </div>
          <div className="navflex">
            <div className={active}>
              <ul className="diflexmenu">
                <li className="nav__item">
                  <Link
                    to="/appointments"
                    className={`menulist ${
                      isActive("/appointments") ? "active" : ""
                    }`}
                    onClick={() => navToggle("appointments")}
                  >
                    <span className="navicon sidebarImg">
                      <img src={navi1} alt="" />
                    </span>
                    Appointments
                  </Link>
                </li>
                <li className="nav__item">
                  <Link
                    to="/allied_task"
                    className="nav__link"
                    onClick={() => navToggle("")}
                  >
                    <span className="navicon sidebarImg">
                      <img src={navi1} alt="" />
                    </span>
                    Tasks
                  </Link>
                </li>
                <li className="nav__item">
                  <Link to={(e) => e.preventDefault()} className="nav__link">
                    <span className="navicon sidebarImg">
                      <img src={navi4} alt="" />
                    </span>
                    Payments
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    to="/allied_home"
                    className={`nav__link ${
                      isActive("/allied_home") ? "active" : ""
                    }`}
                    onClick={() => navToggle("home")}
                  >
                    <span className="navicon">
                      <img src={home} className="imgacctive" alt="" />
                      <img src={home2} className="nonactive" alt="" />
                    </span>
                    Home
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    to="/patient"
                    className={`nav__link ${
                      isActive("/patient") ? "active" : ""
                    }`}
                    onClick={() => navToggle("patient")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={patient} alt="" />
                      <img className="imgacctive" src={patient2} alt="" />
                    </span>
                    PATIENT/ CONSULTATION
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    className={`nav__link ${
                      activeItem === "orders" ? "active" : ""
                    }`}
                    onClick={() => navToggle("orders")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={result} alt="" />
                      <img className="imgacctive" src={result2} alt="" />
                    </span>
                    ORDERS
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    to="/allied_documents"
                    className={`nav__link ${
                      isActive("/allied_documents") ? "active" : ""
                    }`}
                    onClick={() => navToggle("documents")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={documents} alt="" />
                      <img className="imgacctive" src={documents2} alt="" />
                    </span>
                    DOCUMENTS
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    className={`nav__link ${
                      activeItem === "vaccine" ? "active" : ""
                    }`}
                    onClick={() => navToggle("vaccine")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={vaccine} alt="" />
                      <img className="imgacctive" src={vaccine2} alt="" />
                    </span>
                    VACCINE
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    className={`nav__link ${
                      activeItem === "opportunities" ? "active" : ""
                    }`}
                    onClick={() => navToggle("opportunities")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={oppor} alt="" />
                      <img className="imgacctive" src={oppor2} alt="" />
                    </span>
                    OPPORTUNITIES
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    to="/allied_sms"
                    className={`nav__link ${
                      isActive("/allied_sms") ? "active" : ""
                    }`}
                    onClick={() => navToggle("communication")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={communication} alt="" />
                      <img className="imgacctive" src={communication2} alt="" />
                    </span>
                    COMMUNICATIONS
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    className={`nav__link ${
                      activeItem === "reports" ? "active" : ""
                    }`}
                    onClick={() => navToggle("reports")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={reports} alt="" />
                      <img className="imgacctive" src={reports2} alt="" />
                    </span>
                    REPORTS
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    className={`nav__link ${
                      activeItem === "search" ? "active" : ""
                    }`}
                    onClick={() => navToggle("search")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={search} alt="" />
                      <img className="imgacctive" src={search2} alt="" />
                    </span>
                    SEARCH
                  </Link>
                </li>
                <li className="nav__item itemphon">
                  <Link
                    to="/settings"
                    className={`nav__link ${
                      isActive("/settings") ? "active" : ""
                    }`}
                    onClick={() => navToggle("settings")}
                  >
                    <span className="navicon">
                      <img className="nonactive" src={settings} alt="" />
                      <img className="imgacctive" src={settings2} alt="" />
                    </span>
                    SETTINGS
                  </Link>
                </li>
              </ul>
              {/* <Sidebar /> */}
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <div className={`search_bar search-wrapper`}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                value={fullName || null}
                options={options}
                getOptionLabel={(option) =>
                  option && option.title ? option.title : fullName
                }
                onInputChange={onInputChange}
                onChange={(event, newValue) => {
                  setFullName(newValue ? newValue.title : "");
                  setPatientId(newValue ? newValue.id : "");
                  goToPatientDetails(newValue ? newValue.id : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Search here..."
                  />
                )}
              />
              <Image src={srcicon} alt="search-icon" />
            </div>
            <Link
              to="/appointments"
              style={{ color: "#fff" }}
              className={`appointmentsLink ${
                isActive("/appointments") ? "active" : ""
              }`}
              onClick={() => handleMenuClick("appointments")}
            >
              {/* <span className="navicon">
                <img src={navi1} alt="" />
              </span> */}
              Appointments
            </Link>
            <div className="hedconttoggel">
              <div className="logiconbox">
                {selectedUser?.profilePic ? (
                  <img
                    src={selectedUser?.profilePic}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                  />
                ) : (
                  <span
                    className="user-profile-image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                  >
                    {splittedNameState ? splittedNameState[0][0] : ""}
                    {splittedNameState ? splittedNameState[1][0] : ""}
                  </span>
                )}
              </div>
              <div className="dropdown">
                <button
                  className="dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {nameState}
                </button>

                <ul
                  className="dropdown-menu profiledropdown"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="profilelink bdrbttom">
                    <div className="profilelinkimg">
                      {userState.base64Image && (
                        <img src={userState.base64Image} alt="" />
                      )}
                    </div>
                    <div className="profilelinkdetails">
                      {userState && (
                        <>
                          <h6>{nameState}</h6>
                          <p>{userState.email}</p>
                        </>
                      )}
                    </div>
                  </li>
                  <li>
                    <Link
                      className="profilelink"
                      to="/profile"
                      onClick={() => navigate("/profile")}
                    >
                      <div className="dropicon">
                        <img src={dropi1} alt="" />
                      </div>
                      <div className="profilelinkdetails">
                        <h6>Profile</h6>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="profilelink"
                      to="/timeoff"
                      onClick={() => navigate("/timeoff")}
                    >
                      <div className="dropicon">
                        <img src={dropi2} alt="" />
                      </div>
                      <div className="profilelinkdetails">
                        <h6>Time off</h6>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="deletebtn profilelink"
                      data-bs-toggle="modal"
                      data-bs-target="#logoutModal"
                    >
                      <div className="dropicon">
                        <img src={dropi3} alt="" />
                      </div>
                      <div className="profilelinkdetails">
                        <h6>Logout</h6>
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
      )}
      <Drawer variant="outlined" open={open}   anchor="right" BackdropProps={{ invisible: true }}>
        <div className="taskDrawer">
          <button className={s.closeButton}   style={{position: "absolute", right: '30px', top: '10px'}} onClick={event => {
            setOpen(false);
          }}>
            <div className={s.modaHeaderBackground}></div>
            <img src={Close} alt="close icon" />
          </button>

          <div className="informationSummary documents_box">
            {/* General Task start */}
            <div className="summary_whitebox">
              <div className="summary_heading">
                <div className="sum_headicon">
                  <img src={information} alt="" />
                </div>
                <h2>My Tasks</h2>
                <div
                    className="taskbtn "
                    data-bs-toggle="modal"
                    data-bs-target="#addTask_modal"
                    onClick={handleAddTask}
                    style={{ cursor: "pointer" }}
                >
                  <span>Add new</span>
                  <button>
                    +
                  </button>
                </div>
              </div>
              <div className="summary_boddy" style={{maxWidth: '800px'}}>
                <div className="summary_colorbg">
                  <ul>
                    {dataFetching ? null : currentItems.length === 0 ? (
                        <p className="no-record-text">No Records Found</p>
                    ) : (
                        currentItems.map((item, i) => {
                          return (
                              <li
                                  key={i}
                                  data-bs-toggle="modal"
                                  data-bs-target="#displayTask_modal"
                                  onClick={() => handleDisplayTask(item.id)}
                              >
                                <p>
                                  <span className="number-wrap">{i + 1}</span>{" "}
                                  <span className="text-ellipsis">
                                {" "}
                                    <a
                                        href=""
                                        onClick={(e) => {
                                          setOpen(false);
                                          e.preventDefault();
                                          goToPatientDetails(
                                              item.patient.id, getPatientName(item.patient)
                                          );
                                        }}
                                        className="patient-title-wrap"
                                    >
                                    {getPatientName(item.patient)}  </a> - {item.title}
                              </span>
                                </p>
                                <div className="delbtnlist">
                                  <button
                                      className={
                                        item.status == "Complete" ? "green-tick" : ""
                                      }
                                  >
                                    <img src={tick} alt="" />
                                  </button>
                                  <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#myModal"
                                      onClick={() => handleDeleteTask(item.id)}
                                  >
                                    <img src={Delete} alt="" />
                                  </button>
                                </div>
                              </li>
                          );
                        })
                    )}
                  </ul>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
      <LogOutModal />
    </>
  );
}

export default Header;
