import React from "react";

import Close from "../../../assets/images/close-black.svg";

import s from "./Modal.module.css";

import CustomButton from "../CustomButton/CustomButton";

const Modal = ({
  title,
  children,
  onClose,
  onSave,
  width,
  height,
  position, hideCancel
}) => {
  return (
    <div className={s.modalBackdrop}>
      <div style={{ width: width, height: height }} className={s.modalContent}>
        <div className={s.modalHeader}>
          <h2 style={{ fontSize: "24px" }}>{title}</h2>
          <button className={s.closeButton} onClick={onClose}>
            <div className={s.modaHeaderBackground}></div>
            <img src={Close} alt="close icon" />
          </button>
        </div>
        <div className={s.modalBody} style={{ height: height }}>{children}</div>
        <div style={{ justifyContent: position }} className={s.modalFooter}>
          {onClose && !hideCancel && (
            <CustomButton
              buttonClassName={s.footerButton}
              title={"Cancel"}
              icon={false}
              buttonHandle={onClose}
            />
          )}
          {onSave && (
            <CustomButton
              buttonClassName="saveButton savwidth"
              titleClassName="custom_btn savwidth"
              title={"Save"}
              icon={false}
              buttonHandle={onSave}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
