import React from "react";
import style from "./EmptyStateNoConsult.module.css";
import emptyIcon from "../../../../assets/images/consultations.svg";

const EmptyState = () => {
    return (
        <div className={style.emptyState}>
            <img className={style.element} alt="Element" src={emptyIcon} />
            <div className={style.text}>
                <div className={style.div}>
                    <div className={style.textWrapper}>No consultation</div>
                    <p className={style.onceThePatient}>
                        Consultation notes will appear here once patient has first consultation with medical team.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EmptyState;

